import "./style.css";
import { startObserver } from "./utils.js"

// Mountování komponenty
//
// front.js:
// import HelloWorld from "./front/HelloWorld.vue"
// const observedComponents = {
//     ...
//     'HelloWorld': HelloWorld,
// }
//
// HTML:
// <div
//     data-vue-component="HelloWorld"
//     data-vue-use-axios
//     data-prop-1="value-1"
//     data-prop-2="value-2"
//     ...
// >
// </div>

const observedComponents = {

}

startObserver(observedComponents);